
import { UrgentDeliveryDetailData } from "@/domain/entities/UrgentDelivery";
import { PropType } from "vue";
import { Vue, prop } from "vue-class-component";

class Props {
  data = prop<UrgentDeliveryDetailData>({
    required: true,
    default: new UrgentDeliveryDetailData()
  })
  close = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function,
  });
}
export default class Detail extends Vue.with(Props) {}

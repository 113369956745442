import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { UrgentDeliveryPresenter } from "../presenters/UrgentDeliveryPresenter";
import { RequestUrgentDeliveryListApi, RequestUrgentDeliveryListApiDownload } from "@/domain/entities/UrgentDelivery";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "urgent-delivery"
})
class UrgentDeliveryStore extends VuexModule {
  @Action
  public getUrgentDeliveryList(params: RequestUrgentDeliveryListApi) {
    const presenter = container.resolve(UrgentDeliveryPresenter);
    return presenter.getUrgentDeliveryList(params);
  }

  @Action
  public getUrgentDeliverySTTStatusList() {
    const presenter = container.resolve(UrgentDeliveryPresenter);
    return presenter.getUrgentDeliverySTTStatusList();
  }

  @Action
  public getUrgentDeliveryDetail(id: number) {
    const presenter = container.resolve(UrgentDeliveryPresenter);
    return presenter.getUrgentDeliveryDetail(id);
  }

  @Action
  public getUrgentDeliverySTTDownload(params: RequestUrgentDeliveryListApiDownload) {
    const presenter = container.resolve(UrgentDeliveryPresenter);
    return presenter.getUrgentDeliverySTTDownload(params);
  }
}
export const UrgentDeliveryController = getModule(UrgentDeliveryStore);

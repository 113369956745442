export const ELIGIBLE_KIRIMAN_PENTING = [
    "BKD",
    "PUP",
    "STI-SC",
    "STI",
    "BAGGING",
    "E-CARGO/CARGO PLANE",
    "TRUCK/CARGO TRUCK",
    "TRAIN/CARGO TRAIN",
    "STI-DEST",
    "STI DEST-SC",
    "DEL",
    "STT REMOVE",
    "TRANSIT",
    "SHORTLAND",
    "MIS-ROUTE",
    "ODA",
    "REJECTED",
    "HND",
    "STT ADJUSTED",
];

export const ELIGIBLE_DELIVERY_ULANG = [
    "DEX",
    "CODREJ",
    "HAL"
];

export const ELIGIBLE_RTS = [
    "BKD",
    "PUP",
    "STI-SC",
    "STI",
    "BAGGING",
    "E-CARGO/CARGO PLANE",
    "TRUCK/CARGO TRUCK",
    "TRAIN/CARGO TRAIN",
    "STT REMOVE",
    "TRANSIT",
    "REJECTED",
    "STT ADJUSTED",
    "DEX",
    "CODREJ",
    "STI-DEST",
    "STI DEST-SC",
    "DEL",
]

export const ELIGIBLE_HAL = [
    "BKD",
    "PUP",
    "STI-SC",
    "STI",
    "BAGGING",
    "E-CARGO/CARGO PLANE",
    "TRUCK/CARGO TRUCK",
    "TRAIN/CARGO TRAIN",
    "STI-DEST",
    "STI DEST-SC",
    "DEX",
    "STT REMOVE",
    "TRANSIT",
    "SHORTLAND",
    "MIS-ROUTE",
    "ODA",
    "REJECTED",
    "HND",
    "STT ADJUSTED",
    "DEL",
]
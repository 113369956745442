import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full grid grid-cols-2 pb-4" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 py-4" }
const _hoisted_3 = { class: "w-full grid grid-cols-2 py-4" }
const _hoisted_4 = { class: "text-gray-lp-500" }
const _hoisted_5 = { class: "w-full grid grid-cols-2 py-4" }
const _hoisted_6 = { class: "w-full grid grid-cols-1 py-4" }
const _hoisted_7 = { class: "grid grid-cols-4 w-full gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ModalV2 = _resolveComponent("ModalV2")!

  return (_openBlock(), _createBlock(_component_ModalV2, {
    width: "w-6/12",
    showFooter: false,
    onClose: () => _ctx.close(),
    title: _ctx.data.rpdSttNo
  }, {
    content: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DataWrapper, {
          label: "Tipe Permintaan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.rpdRequestType), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Status Terakhir",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.sttLastStatusId), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          label: "Batas Waktu",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.formatDeadline), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Alasan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.rpdReason), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_DataWrapper, {
          label: "Tanggal dibuat",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createVNode("div", null, _toDisplayString(_ctx.data.formatCreatedAt), 1),
            _createVNode("div", _hoisted_4, _toDisplayString(_ctx.data.rpdCreatedBy || "-"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Catatan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.rpdNotes), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_DataWrapper, {
          label: "Tujuan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.sttDestinationCity), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_DataWrapper, { label: "Bukti" }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_7, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data.rpdProofPhoto, (image) => {
                return (_openBlock(), _createBlock("div", {
                  key: image,
                  class: ""
                }, [
                  _createVNode("img", {
                    src: image,
                    alt: "",
                    class: "w-48 h-48 rounded-md"
                  }, null, 8, ["src"])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}
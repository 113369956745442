
import { Vue, Options } from "vue-class-component";
import {
  UrgentDeliveryApiList,
  UrgentDeliveryData,
  RequestUrgentDeliveryListApi,
  UrgentDeliveryDetail,
  UrgentDeliveryDetailData,
  RequestUrgentDeliveryListApiDownload,
} from "@/domain/entities/UrgentDelivery";
import { UrgentDeliveryController } from "@/app/ui/controllers/UrgentDeliveryController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ApiStateData } from "@/domain/entities/Api";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import { ErrorMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import Detail from "./components/detail.vue";
import UploadFileUrgentDelivery from "./components/upload-file-urgent-deilvery/index.vue";
import { RequestPriorityDeliveryController } from "../../controllers/RequestPriorityDeliveryController";
import { MainAppController } from "../../controllers/MainAppController";
import {
  CreateRequestPriorityDelivery,
  RequestPriorityDelivery,
} from "@/domain/entities/RequestPriorityDelivery";
import {
  ELIGIBLE_DELIVERY_ULANG,
  ELIGIBLE_HAL,
  ELIGIBLE_KIRIMAN_PENTING,
  ELIGIBLE_RTS,
} from "./urgent-delivery";
import RadioButton from "primevue/radiobutton";
import { ModalMessageEntities } from "@/domain/entities/MainApp";

@Options({
  components: {
    UploadFileUrgentDelivery,
    RadioButton,
    Detail,
  },
})
export default class UrgentDelivery extends Vue {
  showModalRequestUrgentDelivery = false;
  mounted() {
    this.fetchData();
    this.fetchSTTStatusData();
  }
  get getTitle() {
    return this.$route.meta.title;
  }

  apiListData = new ApiStateData();
  apiDetailData = new ApiStateData();
  urgentDeliveryDetailData = new UrgentDeliveryDetailData();
  urgentDeliveryApiList = new UrgentDeliveryApiList();

  lastStatusData: OptionsClass[] = [];

  firstPagination() {
    this.urgentDeliveryApiList.response.pagination.page = 1;
  }
  async fetchData() {
    try {
      this.apiListData.loading = true;
      this.urgentDeliveryApiList.response = await UrgentDeliveryController.getUrgentDeliveryList(
        new RequestUrgentDeliveryListApi({
          page: this.urgentDeliveryApiList.response.pagination.page,
          limit: this.urgentDeliveryApiList.response.pagination.limit,
          sttNo: this.filter.search,
          startDate: formatDateNumber(this.filter.startDate),
          endDate: formatDateNumber(this.filter.endDate),
          requestType: this.filter.requestType.value,
          lastStatus: this.filter.lastStatus.value,
          orderBy: this.filter.orderBy,
          sortBy: this.filter.sortBy,
        })
      );
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
    }
  }
  async getDetailData(id: number) {
    MainAppController.showLoading();
    try {
      this.urgentDeliveryDetailData = await UrgentDeliveryController.getUrgentDeliveryDetail(
        id
      );
      this.modalDetail = true;
      this.apiDetailData.errorType = "";
    } catch (error) {
      MainAppController.closeLoading();
      MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          type: "client-error",
          title: "Gagal mendapatkan detail!",
          onClose: () => {
            MainAppController.closeErrorMessage();
          },
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
  async fetchSTTStatusData() {
    const resp = await UrgentDeliveryController.getUrgentDeliverySTTStatusList();
    this.lastStatusData = resp.data.map(
      (status) =>
        new OptionsClass({
          name: status.name,
          value: status.name,
        })
    );
  }
  get isEmpty() {
    return (
      !this.urgentDeliveryApiList.response.data.length &&
      !this.apiListData.loading
    );
  }

  columns = [
    {
      name: "No",
      styleHead: "w-1/12 text-left",
      render: (item: UrgentDeliveryData, index: number) => {
        return `<div class="text-black-lp-300">${index + 1}</div>`;
      },
    },
    {
      name: "No. STT",
      styleHead: "w-1/4 text-left",
      render: (item: UrgentDeliveryData) => {
        return `<div class="text-black-lp-300">${item.rpdSttNo}</div>`;
      },
    },
    {
      name: "Tipe permintaan",
      styleHead: "w-1/4 text-left",
      render: (item: UrgentDeliveryData) => {
        return `<div class="text-black-lp-300">${item.rpdRequestType}</div>`;
      },
    },
    {
      name: "Alasan",
      styleHead: "w-2/6 text-left",
      render: (item: UrgentDeliveryData) => {
        return `<div class="text-black-lp-300">${item.rpdReason}</div>`;
      },
    },
    {
      name: "Batas waktu",
      styleHead: "w-1/4 text-left",
      render: (item: UrgentDeliveryData) => {
        return `<div class='text-black-lp-300  flex flex-row'>
                ${item.formatDeadline}
                </div>`;
      },
    },
    {
      name: "Tujuan",
      styleHead: "w-1/6 text-left",
      render: (item: UrgentDeliveryData) => {
        return `<div class="text-black-lp-300">${item.sttDestinationCity}</div>`;
      },
    },
    {
      name: "Status terakhir",
      styleHead: "w-36 text-left",
      render: (item: UrgentDeliveryData) => {
        return `<div class="text-black-lp-300">${item.sttLastStatusId}</div>`;
      },
    },
    {
      name: "Tanggal dibuat",
      styleHead: "w-56 text-left",
      render: (item: UrgentDeliveryData) => {
        return `<div class="text-black-lp-300 break-all">${formatDate(
          item.formatCreatedAt
        )}</div>
          <div class="capitalize text-gray-lp-500 break-all">${ellipsisString(
            item.rpdCreatedBy || "-",
            20
          )}</div>`;
      },
    },
  ];

  filter = {
    search: "",
    startDate: "",
    endDate: "",
    requestType: new OptionsClass(),
    lastStatus: new OptionsClass(),
    orderBy: "date",
    sortBy: "desc",
  };
  get sumFilter(): number {
    return (
      (this.filter.requestType.value ? 1 : 0) +
      (this.filter.lastStatus.value ? 1 : 0)
    );
  }
  //advanced filter
  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onChangeAdvancedFilter() {
    this.firstPagination();
    this.fetchData();
  }
  async onResetAdvancedFilter() {
    this.apiListData.loading = true;
    Object.assign(this.filter, {
      requestType: new OptionsClass(),
      lastStatus: new OptionsClass(),
    });

    this.firstPagination();
    await this.fetchData();
  }
  // filter search
  onSearch(value: string) {
    if (!value.length || value.length >= 3) {
      // this.urgentDeliveryApiList.request.search = value;
      this.filter.search = value;
      this.firstPagination();
      this.fetchData();
      this.isFiltered = true;
    }
  }
  // date
  isFiltered = false
  countDatePicker = 0;
  setDateRange(value: any[]) {
    this.countDatePicker++;
    this.filter.startDate = value[0];
    this.filter.endDate = value[1];

    if (value[0] && value[1]) {
      if (this.countDatePicker > 1) {
        this.isFiltered = true;
      }
      this.fetchData();
    }
  }
  // requestType
  get requestTypeData(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "Semua",
        value: "",
      }),
      new OptionsClass({
        name: "Kiriman penting",
        value: "Kiriman Penting",
      }),
      new OptionsClass({
        name: "Delivery ulang",
        value: "Delivery Ulang",
      }),
      new OptionsClass({
        name: "RTS",
        value: "RTS",
      }),
      new OptionsClass({
        name: "HAL",
        value: "HAL",
      }),
    ];
  }
  selectRequestType(data: OptionsClass) {
    Object.assign(this.filter, { requestType: data });
    this.onChangeAdvancedFilter();
    this.isFiltered = true;
  }

  selectLastStatus(data: OptionsClass) {
    Object.assign(this.filter, { lastStatus: data });
    this.onChangeAdvancedFilter();
    this.isFiltered = true;
  }

  //toggle button
  isToggleSortButtonActive = false;

  toggleSortButton() {
    this.isToggleSortButtonActive = !this.isToggleSortButtonActive;
    this.isToggleSortButtonActive
      ? (this.filter.sortBy = "asc")
      : (this.filter.sortBy = "desc");
    this.firstPagination();
    this.fetchData();
  }

  get getActiveSortButton() {
    return this.isToggleSortButtonActive
      ? {
          textColor: "red-lp-300",
          borderColor: "red-lp-300",
          customClass: "w-40 mr-4 btn-active",
        }
      : {
          textColor: "gray-lp-500",
          borderColor: "",
          customClass: "w-40 mr-4",
        };
  }

  async onDownload() {
    await UrgentDeliveryController.getUrgentDeliverySTTDownload(
      new RequestUrgentDeliveryListApiDownload({
          sttNo: this.filter.search,
          startDate: formatDateNumber(this.filter.startDate),
          endDate: formatDateNumber(this.filter.endDate),
          requestType: this.filter.requestType.value,
          lastStatus: this.filter.lastStatus.value,
          orderBy: this.filter.orderBy,
          sortBy: this.filter.sortBy,
        })
    );
  }

  modalDetail = false;
  async goToDetail(item: UrgentDeliveryData) {
    await this.getDetailData(item.rpdId);
  }
  closeDetail() {
    this.modalDetail = false;
  }
  form = {
    sttNo: "",
    requestType: "",
    reasonType: "" as any,
    remarks: "",
  };
  captionWarning = "";
  detailSTT = new RequestPriorityDelivery({});
  error = {
    sttNo: false,
    captionError: "",
  };

  get isDisabled() {
    return (
      this.form.sttNo.length < 17 ||
      !this.form.requestType ||
      !this.form.reasonType ||
      !this.form.remarks ||
      !this.attachments.length ||
      this.error.sttNo ||
      !!this.captionWarning
    );
  }
  get attachments() {
    return RequestPriorityDeliveryController.attachments.map(
      (key: any) => key.source
    );
  }
  onBlurSttNo() {
    if (this.form.sttNo.length < 17) {
      this.error.sttNo = true;
      this.error.captionError = "Nomor STT tidak valid, cek & ubah lagi.";
    }
    // to do on blur
  }
  onInputSttNo() {
    if (this.form.sttNo.length === 17) this.getDetailSttNo();
    // to do on input
  }
  onChangeSttNo() {
    this.error.sttNo = false;
    this.error.captionError = "";
    this.captionWarning = "";
    this.requestTypes.forEach((element: any) => {
      element.disabled = false;
    });
    this.detailSTT = new RequestPriorityDelivery({});
  }
  get isDEXOrCODREJ() {
    return (
      this.detailSTT.sttLastStatusId === "DEX" ||
      this.detailSTT.sttLastStatusId === "CODREJ"
    );
  }
  onChangeRadioButton() {
    if (this.form.requestType === "Delivery Ulang" && this.isDEXOrCODREJ) {
      this.form.reasonType = this.detailSTT.deliveryReasonTitle;
    } else if (this.form.requestType === "Delivery Ulang" && this.detailSTT.sttLastStatusId === "HAL") {
      this.form.reasonType = "Permintaan customer paket ditahan di lokasi HUB"
    } else {
      this.form.reasonType =
        (this.form.requestType === "RTS" && !this.isDEXOrCODREJ)
          ? "Permintaan retur dari customer"
          : ""
    }
  }
  async getDetailSttNo() {
    try {
      MainAppController.showLoading();
      const resp = await RequestPriorityDeliveryController.getDetailRpd(
        this.form.sttNo
      );
      if (!resp.rpdRequestType.length) {
        this.detailSTT = resp;
        this.onCheckDetailSTT(this.detailSTT.sttLastStatusId); // check last status eligible or not for validate input sttno
        // check product type jumbopackh2h
        if (this.detailSTT.sttProductType.toUpperCase() === 'JUMBOPACKH2H') {
          this.error.sttNo = true;
          this.error.captionError = "Paket Jumbopack akan diambil oleh penerima";
        }
        this.onSetDisableOptionRequestType(); //disabled option by last status eligble or not
      } else {
        this.captionWarning = resp.rpdRequestType;
      }
    } catch (error) {
      this.error.sttNo = true;
      this.error.captionError = "Nomor STT tidak valid, cek & ubah lagi.";
    } finally {
      MainAppController.closeLoading();
      MainAppController.closeErrorMessage();
    }
  }
  onCheckDetailSTT(lastStatus: string) {
    const ALL_STATUS = [
      ...ELIGIBLE_KIRIMAN_PENTING,
      ...ELIGIBLE_DELIVERY_ULANG,
      ...ELIGIBLE_RTS,
      ...ELIGIBLE_HAL,
    ];
    const filterSTT = ALL_STATUS.filter(
      (key) => key.includes(lastStatus) || key === lastStatus
    );
    if (!filterSTT.length) {
      this.error.sttNo = true;
      this.error.captionError = "Nomor STT tidak valid, cek & ubah lagi.";
    }
  }
  onSetDisableOptionRequestType() {
    this.requestTypes[0].disabled = this.disabledOptionKirimanPenting;
    this.requestTypes[1].disabled = this.disabledOptionDeliveryUlang;
    this.requestTypes[2].disabled = this.disabledOptionRTS;
    this.requestTypes[3].disabled = this.disabledOptionHAL;
  }
  get disabledOptionKirimanPenting() {
    const result = [...ELIGIBLE_KIRIMAN_PENTING].filter(
      (key) =>
        key.includes(this.detailSTT.sttLastStatusId) ||
        key === this.detailSTT.sttLastStatusId
    );
    return this.detailSTT.sttLastStatusId.length && !result.length;
  }
  get disabledOptionDeliveryUlang() {
    const result = [...ELIGIBLE_DELIVERY_ULANG].filter(
      (key) =>
        key.includes(this.detailSTT.sttLastStatusId) ||
        key === this.detailSTT.sttLastStatusId
    );
    return this.detailSTT.sttLastStatusId.length && !result.length;
  }
  get disabledOptionRTS() {
    const result = [...ELIGIBLE_RTS].filter(
      (key) =>
        key.includes(this.detailSTT.sttLastStatusId) ||
        key === this.detailSTT.sttLastStatusId
    );
    return this.detailSTT.sttLastStatusId.length && !result.length;
  }
  get disabledOptionHAL() {
    const result = [...ELIGIBLE_HAL].filter(
      (key) =>
        key.includes(this.detailSTT.sttLastStatusId) ||
        key === this.detailSTT.sttLastStatusId
    );
    return this.detailSTT.sttLastStatusId.length && !result.length;
  }

  async onSave() {
    try {
      MainAppController.showLoading();
      MainAppController.closeErrorMessage();
      const resp = await RequestPriorityDeliveryController.createRpd(
        new CreateRequestPriorityDelivery({
          rpdSttNo: this.form.sttNo,
          rpdRequestType: this.form.requestType,
          rpdReason: this.form.reasonType,
          rpdProofPhoto: this.attachments,
          rpdNotes: this.form.remarks,
        })
      );
      this.onShowModalRequestUrgentDelivery();
      this.filter = {
        search: "",
        startDate: "",
        endDate: "",
        requestType: new OptionsClass(),
        lastStatus: new OptionsClass(),
        orderBy: "date",
        sortBy: "desc",
      };
      this.isToggleSortButtonActive = false;
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          image: "image-modal-success",
          title: "Permintaan berhasil dibuat",
          message: `Permintaan kiriman penting <b>“No. STT ${resp.sttNumber}”</b> berhasil dibuat.`,
          textSuccess: "Oke",
          onSubmit: () => {
            MainAppController.closeMessageModal();
            this.firstPagination();
            this.fetchData();
          },
        })
      );
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Permintaan gagal dibuat", () =>
          this.onSave()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
  requestTypes: any = [
    { name: "Kiriman Penting", value: "Kiriman Penting", disabled: false },
    { name: "Delivery Ulang", value: "Delivery Ulang", disabled: false },
    { name: "RTS", value: "RTS", disabled: false },
    { name: "HAL", value: "HAL", disabled: false },
  ];

  captionSTT = {
    isWarning: false,
    isError: false,
    caption: "",
  };
  get showSelectTypes() {
    return (
      this.form.requestType === "Kiriman Penting" ||
      this.form.requestType === "HAL" ||
      (this.form.requestType === "RTS" &&
        (this.detailSTT.sttLastStatusId === "DEX" ||
          this.detailSTT.sttLastStatusId === "CODREJ"))
    );
  }
  get reasonTypes() {
    let result: any[] = [];
    switch (this.form.requestType) {
      case "Kiriman Penting":
        result = this.reasonTypeKirimanPenting;
        break;
      case "HAL":
        result = this.reasonTypeHal;
        break;
      case "RTS":
        result = [
          ...this.reasonTypeRTS,
          {
            name: this.detailSTT.deliveryReasonTitle,
            value: this.detailSTT.deliveryReasonTitle,
          },
          {
            name: "Permintaan retur dari customer",
            value: "Permintaan retur dari customer",
          },
        ];
        break;
      default:
        result = [
          {
            name: "Pilih Alasan",
            value: "",
          },
        ];
        break;
    }
    return result;
  }
  reasonTypeKirimanPenting = [
    {
      name: "Pilih Alasan",
      value: "",
    },
    {
      name: "Acara Khusus",
      value: "Acara Khusus",
    },
    {
      name: "Makanan",
      value: "Makanan",
    },
    {
      name: "Kiriman Obat",
      value: "Kiriman Obat",
    },
    {
      name: "Tanaman Hidup",
      value: "Tanaman Hidup",
    },
  ];
  reasonTypeHal = [
    {
      name: "Pilih Alasan",
      value: "",
    },
    {
      name: "Double STT",
      value: "Double STT",
    },
    {
      name: "Kurang Koli",
      value: "Kurang Koli",
    },
    {
      name: "Penerima Ambil Paket Sendiri",
      value: "Penerima Ambil Paket Sendiri",
    },
    {
      name: "Pengirim Kurang Bayar Customer Apps",
      value: "Pengirim Kurang Bayar Customer Apps",
    },
    {
      name: "Permintaan Perubahan Alamat",
      value: "Permintaan Perubahan Alamat",
    },
    {
      name: "Permintaan Retur Dari Customer",
      value: "Permintaan Retur Dari Customer",
    },
  ];
  reasonTypeRTS = [
    {
      name: "Pilih Alasan",
      value: "",
    },
  ];
  onShowModalRequestUrgentDelivery() {
    this.showModalRequestUrgentDelivery = !this.showModalRequestUrgentDelivery;
    this.onResetForm();
  }
  onResetForm() {
    RequestPriorityDeliveryController.deleteAttachment([]);
    this.form = {
      sttNo: "",
      requestType: "",
      reasonType: "",
      remarks: "",
    };
    this.error.sttNo = false;
    this.error.captionError = "";
    this.requestTypes.forEach((element: any) => {
      element.disabled = false;
    });
    this.detailSTT = new RequestPriorityDelivery({});
    this.captionWarning = "";
  }
  isOpenSelectReasonTypes = false;
  onOpenSelectReasonTypes() {
    this.isOpenSelectReasonTypes = true;
  }
  onCloseSelectReasonTypes() {
    this.isOpenSelectReasonTypes = false;
  }
  onSelectReasonTypes(_: string, value: string) {
    this.onCloseSelectReasonTypes();
    this.form.reasonType = value;
  }
}


import formatBytes from "@/app/infrastructures/misc/common-library/FormatBytes";
import {
  ellipsisString,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RequestPriorityDeliveryController } from "@/app/ui/controllers/RequestPriorityDeliveryController";
import {
  DeleteHydraFileRequest,
  UploadHydraFileRequest,
} from "@/data/payload/api/BookingRequest";
import { Options, prop, Vue } from "vue-class-component";
class Props {
  maxPhoto = prop<number>({
    default: 1,
    type: Number,
  });
}
@Options({
  components: {
    UploadFile,
  },
})
export default class UploadFileUrgentDelivery extends Vue.with(Props) {
  attachmentImage: any = "";

  get attachments() {
    return RequestPriorityDeliveryController.attachments;
  }

  mounted() {
    this.attachmentImage = "";
  }

  async onUpload() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      if (this.attachmentImage) {
        const fileLink = await BookingController.uploadHydraFile(
          new UploadHydraFileRequest({
            attachFile: this.attachmentImage,
            folderPath: "urgent-delivery-requests/",
          })
        );
        RequestPriorityDeliveryController.addAttachment({
          image: URL.createObjectURL(this.attachmentImage),
          source: fileLink.data,
          size: formatBytes(this.attachmentImage.size),
          name: this.ellipsisString(this.attachmentImage.name),
          status: "success",
        });
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, this.$t("bookingShipment.error.uploadPhoto"))
      );
    } finally {
      MainAppController.closeLoading();
      this.attachmentImage = "";
    }
  }

  ellipsisString(value: string) {
    return ellipsisString(value, 50);
  }

  async onRemove(indexArr: number) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      const del = await BookingController.deleteHydraFile(
        new DeleteHydraFileRequest({
          attachFiles: [this.attachments[indexArr].source],
        })
      );
      const statusDel = del.data[this.attachments[indexArr].source];
      if (statusDel) {
        RequestPriorityDeliveryController.deleteAttachment(
          this.attachments.filter((_: any, index: any) => index !== indexArr)
        );
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Gagal menghapus Foto ke storage!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
